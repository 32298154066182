<template>
    <div class="container details-page">
        <div class="breadcrumbs">
            <div class="row">
                <div class="col-12">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                            <li class="breadcrumb-item"><a href="/">Order Success</a></li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>

        <section class="section">
            <div class="section-contents text-justify blog-detail">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="card-header order-success-heading text-center">
                            <div class="d-flex justify-content-center">
                                <div>
                                    <img src="/img/cart-success.png" style="max-height: 60px;">
                                </div>
                                <div class="ml-4 text-left">
                                    <div class="order-no">Order Number: {{orderDetails.order_no}}</div>
                                    <p>Your order is on it's way <br>please pay with <span class="text-warning">Cash on Delivery</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4 justify-content-center">
                    <div class="col-md-4">
                        <div class="delivery-box text-center">
                            <h6 class="text-muted">Delivery Address</h6>
                            <p>{{orderDetails.shipping_address.address_1}}, {{orderDetails.shipping_address.city}}, {{orderDetails.shipping_address.zip_code}}</p>
                            <br/>
                            <br/>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="summary-box text-center">
                            <h6 class="text-muted">Order Summary</h6>
                            <div class="d-flex justify-content-between">
                                <div>Subtotal</div>
                                <div>{{orderDetails.sub_total}}</div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div>Delivery Charge</div>
                                <div>{{orderDetails.shipping_cost}}</div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div>Order Total</div>
                                <div>{{orderDetails.grand_total}}</div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div class="text-danger font-weight-bold">Amount Paid</div>
                                <div>0</div>
                            </div>
                        </div>
                        <div class="due d-flex justify-content-between font-weight-bold">
                            <div>Due</div>
                            <div>{{orderDetails.grand_total}}</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="row mt-4 justify-content-center">
                    <div class="col-md-8 mb-3 font-weight-bold">Order Instruction</div>
                </div> -->
                <div class="row justify-content-center mt-5">
                    <!-- <div class="col-md-4">
                        <div class="">
                            <div><textarea type="text" class="form-control" placeholder="e.g. Please call me when you active"></textarea></div>
                            <div class="mt-2"><button type="submit" class="btn btn-info btn-sm">Submit</button></div>
                        </div>
                    </div> -->
                    <div class="col-md-4">
                        <div class="text-center font-weight-bold">
                            <div class="mr-2 mt-1">Would you like to go to your Order List?</div>
                            <div>
                                <router-link :to="{ name: 'Orders'}" class="btn btn-info btn-sm mt-1">Order List</router-link></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>
<script>
    import $ from 'jquery'
    import {mapGetters} from 'vuex';

    export default {
        name: 'CheckOut',
        components: {},
        computed: {
            ...mapGetters([
                'cart',
                'itemsCount',
                'subTotal'
            ])
        },
        data() {
            return {
                orderDetails: null,
            };
        },
        mounted() {
            this.getOrderDetails();
        },
        methods: {
            getOrderDetails() {
                this.$authHttp.get('order-details/' + this.$route.params.id).then((response) => {
                    this.orderDetails = response.data.data;
                    console.log(this.orderDetails);
                }).catch((e) => {
                    console.log(e);
                })
            }
        }
    }
</script>
