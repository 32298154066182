<template>
  <div class="container details-page">
    <div class="breadcrumbs">
      <div class="row">
        <div class="col-12">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item"><a href="/">Category</a></li>
              <li class="breadcrumb-item">{{ $route.params.name }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 d-none d-lg-block">
        <CategoryDetailsMenu></CategoryDetailsMenu>
      </div>
      <div class="col-12 col-lg-9">
        <section class="category-items p-0">
          <div v-if="layout == 'grid'" class="items-grid-view">
            <div class="row grid">
              <a href="#" v-for="(item, key) in categoryItems" v-bind:key="key">
                <div
                  class="section-item d-flex rounded-lg justify-content-center flex-column"
                >
                  <div class="item-image-holder">
                    <a href="javascript:;" @click="itemDetails(item)">
                      <img
                        :src="`${IMAGEURL}${item.thumb_image}`"
                        class="img-responsive"
                      />
                    </a>
                  </div>
                  <div class="pb-3 item-title">{{ item.name }}</div>
                  <div class="item-price pb-2">
                    <span 
                      >৳ {{ item.sales_price }}</span
                    >
                    <!-- <span v-else>৳ {{ item.wholesales_price }}</span> -->
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div v-if="layout == 'list'" class="items-list-view">
            <div
              v-for="(row, key) in categoryItems"
              v-bind:key="key"
              class="section-item"
            >
              <div class="row">
                <div class="col-3">
                  <div class="item-image-holder" @click="itemDetails(row)">
                    <a href="javascript:;">
                      <img
                        :src="`${IMAGEURL}${row.thumb_image}`"
                        class="img-responsive"
                      />
                    </a>
                  </div>
                </div>
                <div class="col-9">
                  <div class="item-inner">
                    <div class="item-title mt-4" @click="itemDetails(row)">
                      <a href="javascript:;">
                        {{ row.name }}
                      </a>
                    </div>

                    <div class="item-price">
                      <span class="currency">৳</span> {{ row.sales_price }}
                    </div>
                    <div class="my-3">
                      <button href="" class="btn btn-primary mr-1">
                        Buy Now
                      </button>
                      <button
                        href=""
                        class="btn btn-warning btn-add-to-cart"
                        @click="addToCart(row)"
                      >
                        Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import CategoryDetailsMenu from "./CategoryDetailsMenu.vue";

export default {
  name: "HelloWorld",
  data() {
    return {
      layout: "grid",
      categoryItems: null,
      filterData: {
        id: this.$route.params.id,
        store: this.$route.query.store,
        color: this.$route.query.color,
        priceRange: this.$route.query.priceRange,
      },
    };
  },
  components: {
    CategoryDetailsMenu,
  },
  methods: {
    viewToggle(type) {
      this.layout = type;
    },
    itemDetails(item) {
      this.$router.push("/item/" + item.id + "/" + item.slug);
    },
    getCategoryProducts() {
      console.log("filterData===========>", this.filterData);
      this.$http
        .post("category", this.filterData)
        .then((response) => {
          this.categoryItems = response.data.data;
          console.log(this.categoryItems);
        })
        .catch((e) => {
          this.categoryItems = [];
        });
    },
    addToCart(row) {
      let itemObj = {
        session_id: localStorage.getItem("session_id"),
        product_id: row.id,
        quantity: 1,
      };
      $(".cart-details-container").addClass("cart-in");
      this.$store.dispatch("addToCart", itemObj);
    },
  },
  mounted() {
    this.getCategoryProducts();
  },
  watch: {
    "$route.params.id"(newId, oldId) {
      this.filterData.id = newId;
      this.getCategoryProducts();
    },
  },
};
</script>
