<template>
  <div class="container details-page">
    <div class="breadcrumbs">
      <div class="row">
        <div class="col-lg-6"><h2>News & stories</h2></div>
        <div class="col-lg-auto ml-auto col-12">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item">News & stories</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
    <section class="section" :style="cmsStyle">
      <div class="section-contents text-justify blog-detail">
        <div class="row blog-row">
          <div
            v-for="(item, key) in dataRows"
            v-bind:key="key"
            class="col-lg-3 text-center"
          >
            <div v-if="item.custom_field1 == 1">
              <a :href="`${item.short_description}`" target="_blank">
                <img
                  :src="`${IMAGEURL}${item.image}`"
                  class="img-responsive news-image"
                />
              </a>
              <a :href="`${item.short_description}`" class="text-white" target="_blank">
                <h4>
                  {{ item.title }}
                </h4>
              </a>
            </div>
            <div v-else>
              <router-link
                :to="{
                  name: 'CMS',
                  params: { id: item.id, slug: item.slug },
                }"
              >
                <img
                  :src="`${IMAGEURL}${item.image}`"
                  class="img-responsive news-image"
                />
              </router-link>
              <router-link
                :to="{
                  name: 'CMS',
                  params: { id: item.id, slug: item.slug },
                }"
              >
                <h4 class="text-white">
                  {{ item.title }}
                </h4>
              </router-link>
              <div v-html="item.short_description"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      dataRows: [],
    };
  },
  methods: {
    getData() {
      this.$http
        .get("cms/News-And-Stories")
        .then((response) => {
          this.dataRows = response.data.data;
        })
        .catch((e) => {
          this.dataRows = [];
        });
    },
  },
  mounted() {
    this.getData();
  },
  computed: {
    cmsStyle() {
      return "background: green;" + "color: #fff";
    },
  },
};
</script>
