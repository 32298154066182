import Vue from 'vue'
import App from './App.vue'
import store from './store';
import router from './router';
import axios from 'axios'
import i18n from './plugins/i18n';
import _ from 'lodash';
import FlashMessage from '@smartweb/vue-flash-message';
Vue.use(FlashMessage);
import BackToTop from 'vue-backtotop'
Vue.use(BackToTop)
Vue.prototype._ = _
Vue.use(require('vue-cookies'))

import Photoswipe from 'vue-pswipe'
Vue.use(Photoswipe)

import helper from './helper/utils';
Vue.use({
  install() {
      Vue.helper = helper;
      Vue.prototype.$helper = helper;
  }
});

import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing)

Vue.config.productionTip = false
if (localStorage.getItem("session_id") === null) {
    localStorage.setItem('session_id', new Date().valueOf());
}
const base = axios.create({
    baseURL: process.env.VUE_APP_APIURL,
})
Vue.prototype.$http = base

const auth = axios.create({
    baseURL: process.env.VUE_APP_APIURL,
    headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
    }
})
Vue.prototype.$authHttp = auth

Vue.IMAGEURL = Vue.prototype.IMAGEURL = process.env.VUE_APP_IMAGEURL
Vue.APIURL = Vue.prototype.APIURL = process.env.VUE_APP_APIURL
Vue.APIBASE = Vue.prototype.APIBASE = process.env.VUE_APP_APIBASE

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/custom.css'
import './assets/css/responsive.css'

new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
}).$mount(`#app`);