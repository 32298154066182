<template>
  <div class="container details-page">
    <Breadcrumbs title="Multimedia Corner"></Breadcrumbs>

    <section class="section" :style="cmsStyle">
      <div class="section-contents text-justify blog-detail">
        <div class="row blog-row">
          <div
            v-for="(item, key) in dataRows"
            v-bind:key="key"
            class="col-lg-3 text-center"
          >
            <div v-if="item.short_description">
              <iframe
                width="100%"
                height="242px"
                :src="`${item.short_description}`"
              >
              </iframe>
            </div>
            <div v-else>
              <video
                class="video"
                :src="`${IMAGEURL}${item.image}`"
                id="video-container"
                width="100%"
                controls
              ></video>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from "../../layouts/common/Breadcrumbs.vue";

export default {
  name: "HelloWorld",
  components: {
    Breadcrumbs,
  },
  data() {
    return {
      dataRows: [],
    };
  },
  methods: {
    getData() {
      this.$http
        .get("cms/Multimedia-Corner")
        .then((response) => {
          this.dataRows = response.data.data;
        })
        .catch((e) => {
          this.dataRows = [];
        });
    },
  },
  mounted() {
    this.getData();
  },
  computed: {
    cmsStyle() {
      return "background: green;" + "color: #fff";
    },
  },
};
</script>
