<template>
  <div class="container details-page">
    <Breadcrumbs title="Photo Gallery"></Breadcrumbs>

    <section class="section">
      <div class="section-contents text-justify blog-detail">
        <div class="row blog-row">
          <div
            v-for="(item, key) in dataRows"
            v-bind:key="key"
            class="col-lg-3 text-center"
          >
            <router-link
              :to="{
                name: 'GalleryDetails',
                params: { id: item.id },
              }"
            >
              <img
                :src="`${IMAGEURL}${item.image}`"
                class="img-responsive news-image"
              />
            </router-link>
            <router-link
              :to="{
                name: 'GalleryDetails',
                params: { id: item.id },
              }"
            >
              <h4>
                {{ item.title }}
              </h4>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from "../../layouts/common/Breadcrumbs.vue";

export default {
  name: "HelloWorld",
  components: {
    Breadcrumbs,
  },
  data() {
    return {
      dataRows: [],
    };
  },
  methods: {
    getData() {
      this.$http
        .get("cms/Gallery")
        .then((response) => {
          this.dataRows = response.data.data;
        })
        .catch((e) => {
          this.dataRows = [];
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
