<template>
    <div class="container details-page">
        <div class="row">
            <div class="col-lg-3 d-none d-lg-block">
                <Menu></Menu>
            </div>
            <div class="col-12 col-lg-9">
                <!---section start--------->
                <section class="section">
                    <div class="section-contents pt-1">
                        <div class="row">
                            <div class="col-lg-12 profile-details">
                                <h2>Your Wishlist</h2>
                                <div class="table-responsive">
                                    <table class="table table-striped table-hover">
                                        <thead>
                                        <tr>
                                            <th scope="col">Picture</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Model</th>
                                            <th scope="col" class="text-right">Unit Price</th>
                                            <th scope="col" class="text-center">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(row, key) in wishlist" v-bind:key="key" class="wishlist-row">
                                            <td>
                                                <router-link :to="{ name: 'ItemDetails', params: { id: row.product.id, slug: row.product.slug }}">
                                                    <img :src="`${IMAGEURL}${row.product.thumb_image}`" class="img-responsive">
                                                </router-link>
                                            </td>
                                            <td>
                                                <router-link :to="{ name: 'ItemDetails', params: { id: row.product.id, slug: row.product.slug }}">
                                                    {{row.product.name}}
                                                </router-link>
                                            </td>
                                            <td>  {{row.product.model}}</td>
                                            <td class="text-right"><span class="currency">৳</span> {{row.price}}</td>
                                            <td class="text-center">
                                                <button @click="addToCart(row)" class="btn btn-warning btn-xs mr-1"><i class="fa fa-shopping-cart"></i> Add to Cart</button>
                                                <button @click="removeFromWishlist(row)" class="btn btn-danger btn-xs"><i class="fa fa-trash"></i> Remove</button>
                                            </td>
                                        </tr>
                                        <tr v-if="wishlist.length < 1">
                                            <th scope="row" colspan="5">
                                                No data found
                                            </th>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery'
    import Menu from './Menu.vue'
    import {mapGetters} from 'vuex';

    export default {
        name: 'HelloWorld',
        data() {
            return {
                noDataFound: false,
                list: null,
            }
        },
        computed: {
            ...mapGetters([
                'wishlist',
            ])
        },
        components: {
            Menu,
        },
        methods: {
            removeFromWishlist(row) {
                var payload = {id: row.id}
                this.$store.dispatch('deleteFromWishlist', payload);
            },
            addToCart(row) {
                let itemObj = {
                    session_id: localStorage.getItem('session_id'),
                    customer_id: localStorage.getItem('customer_id'),
                    product_id: row.product.id,
                    quantity: 1,
                }
                $('.cart-details-container').addClass('cart-in');
                this.$store.dispatch('addToCart', itemObj)
                //remove from wishlist
                var payload = {id: row.id}
                this.$store.dispatch('deleteFromWishlist', payload);
            },
        },
        mounted() {
            this.$store.dispatch('getWishlist');
        }
    }
</script>