<template>
  <div class="container details-page">
    <div class="breadcrumbs">
      <div class="row">
        <div class="col-12">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'Orders' }">My Orders</router-link>
              </li>
              <li class="breadcrumb-item">Review</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <section class="section">
      <div class="section-contents text-justify blog-detail p-4">
        <h5>Order Review</h5>
        <form
          class=""
          id="comments"
          @submit="checkForm"
          action="#"
          method="post"
        >
          <div class="form-row">
            <div class="mb-2">
              <div
                class="starrating risingstar d-flex justify-content-center flex-row-reverse"
              >
                <input
                  type="radio"
                  id="star5"
                  name="rating"
                  v-model="formData.rating"
                  value="5"
                />
                <label for="star5" title="5 star"></label>
                <input
                  type="radio"
                  id="star4"
                  name="rating"
                  v-model="formData.rating"
                  value="4"
                />
                <label for="star4" title="4 star"></label>
                <input
                  type="radio"
                  id="star3"
                  name="rating"
                  v-model="formData.rating"
                  value="3"
                />
                <label for="star3" title="3 star"></label>
                <input
                  type="radio"
                  id="star2"
                  name="rating"
                  v-model="formData.rating"
                  value="2"
                />
                <label for="star2" title="2 star"></label>
                <input
                  type="radio"
                  id="star1"
                  name="rating"
                  v-model="formData.rating"
                  value="1"
                />
                <label for="star1" title="1 star"></label>
              </div>
            </div>
          </div>

          <div class="form-row">
            <label for="inputAddress2">Review</label>
            <textarea
              type="text"
              class="form-control"
              v-model="formData.comments"
              id="inputAddress2"
              placeholder=""
              autocomplete="off"
            ></textarea>
          </div>

          <div class="form-row mt-2">
            <button type="submit" class="btn btn-primary theme-bg">
              Submit
            </button>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import $ from "jquery";

export default {
  data() {
    return {
      formData: {
        rating: 5,
      },
    };
  },
  methods: {
    checkForm: function(e) {
      e.preventDefault();
      if (!this.formData.comments) {
        this.flashMessage.error({
          message: "Comments required",
        });
        return false;
      }

      this.$authHttp
        .post("post-reviews", {
          ...this.formData,
          product_id: this.$route.params.id,
          order_id: this.$route.params.order_id,
        })
        .then((response) => {
          if (response.data == 2) {
            this.flashMessage.success({
              message: "You have already reviewed.",
            });
            this.$router.back();
          } else if (response.data == 1) {
            this.flashMessage.success({
              message: "Review added successfully.",
            });
          } else {
            this.flashMessage.error({
              message: "Something went wrong.",
            });
          }
        })
        .catch((e) => {
            this.flashMessage.error({
              message: "Something went wrong.",
            });
        });
    },
  },
};
</script>
