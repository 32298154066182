<template>
  <div class="container details-page">
    <div class="breadcrumbs">
      <div class="row">
        <div class="col-12">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item"><a href="/">Offer Items</a></li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-12">
        <section class="category-items p-0">
          <div v-if="layout == 'grid'" class="items-grid-view">
            <div class="row grid">
              <a href="#" v-for="(item, key) in offerItems.data" v-bind:key="key">
                <div
                  class="section-item d-flex rounded-lg justify-content-center flex-column"
                >
                  <div class="item-image-holder">
                    <a href="javascript:;" @click="itemDetails(item)">
                      <img
                        :src="`${IMAGEURL}${item.thumb_image}`"
                        class="img-responsive"
                      />
                    </a>
                  </div>
                  <div class="pb-3 item-title">{{ item.name }}</div>
                  <div class="item-price pb-2">
                    <span v-if="item.retail_status == 'Yes'">
                      ৳ {{ item.sales_price }}
                      <strike>৳ {{ item.cost_price }}</strike></span
                    >
                    <span v-else
                      >৳ {{ item.wholesales_price }}
                      <span class="text-muted"
                        ><strike
                          >৳ {{ item.wholesale_cost_price }}</strike
                        ></span
                      >
                    </span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </section>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center mt-3">
            <li class="page-item">
              <a class="page-link" :class="{ 'disabled' : offerItems.current_page == 1 }" href="javascript:;" @click="getOfferProducts(offerItems.current_page - 1)" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">Previous</span>
              </a>
            </li>
            <li class="page-item" v-for="index in offerItems.last_page" :key="index">
              <a class="page-link" href="javascript:;" @click="getOfferProducts(index)">{{ index }}</a>
            </li>
            <li class="page-item">
              <a class="page-link" :class="{ 'disabled' : offerItems.current_page == offerItems.last_page }" href="javascript:;" @click="getOfferProducts(offerItems.current_page + 1)" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "HelloWorld",
  data() {
    return {
      layout: "grid",
      offerItems: [],
      filterData: {
        id: this.$route.params.id,
      },
    };
  },
  methods: {
    viewToggle(type) {
      this.layout = type;
    },
    itemDetails(item) {
      this.$router.push("/item/" + item.id + "/" + item.slug);
    },
    async getOfferProducts(page=1) {
      await this.$http
        .get(`offer-products?page=${page}`)
        .then((response) => {
          this.offerItems = response.data;
          console.log(response);
        })
        .catch((e) => {
          this.offerItems = [];
        });
    },
    addToCart(row) {
      let itemObj = {
        session_id: localStorage.getItem("session_id"),
        product_id: row.id,
        quantity: 1,
      };
      $(".cart-details-container").addClass("cart-in");
      this.$store.dispatch("addToCart", itemObj);
    },
  },
  mounted() {
    this.getOfferProducts();
  },
};
</script>
