<template>
    <div class="container details-page">
        <div class="row">
            <div class="col-lg-3 d-none d-lg-block">
                <Menu></Menu>
            </div>
            <div class="col-12 col-lg-9">
                <!---section start--------->
                <section class="section">
                    <div class="section-contents pt-1">
                        <div class="row">
                            <div class="col-lg-12 profile-details">
                                <h2>
                                    Your Shipping Addresses
                                    <button class="btn btn-sm btn-outline-success float-right" @click="addShipping">Add New</button>
                                    <div class="clearfix"></div>
                                </h2>
                                <div class="table-responsive">
                                    <table class="table table-striped table-hover">
                                        <tbody>
                                        <tr v-for="(row, key) in addresses" v-bind:key="key">
                                            <th scope="row">
                                                <div>{{row.first_name}} ({{row.phone}})</div>

                                                <div> {{row.address_1}}, {{row.city}}, {{row.zip_code}}</div>

                                                <div>{{row.city}}</div>
                                            </th>
                                            <td class="text-right">
                                                <button class="btn btn-primary btn-sm" @click="edit(row)">Edit</button>
                                            </td>
                                        </tr>
                                        <tr v-if="notFound">
                                            <th scope="row" colspan="2">
                                                No data found
                                            </th>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

            </div>
        </div>
        <div class="modal" id="addShippingModal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <form @submit="addShippingAddress">
                        <div class="modal-header">
                            <h5 class="modal-title">Add New</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body ">
                            <div class="form-group">
                                <label>Full Name<span class="required">&nbsp;*</span></label>
                                <input type="text" ref="first_name" v-model="formData.first_name" class="form-control" placeholder="Enter full name" autocomplete="off">
                            </div>

                            <div class="form-group">
                                <label>Mobile<span class="required">&nbsp;*</span></label>
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">+88</span>
                                    </div>
                                    <input type="text" ref="phone" v-model="formData.phone" class="form-control" placeholder="Enter your mobile number" autocomplete="off">
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Address<span class="required">&nbsp;*</span></label>
                                <input type="text" ref="address_1" v-model="formData.address_1" class="form-control" placeholder="Enter address" autocomplete="off">
                            </div>

                            <div class="form-group">
                                <label>City<span class="required">&nbsp;*</span></label>
                                <input type="text" ref="city" v-model="formData.city" class="form-control" placeholder="Enter city" autocomplete="off">
                            </div>

                            <div class="form-group">
                                <label>Post Code<span class="required">&nbsp;*</span></label>
                                <input type="text" ref="zip_code" v-model="formData.zip_code" class="form-control" placeholder="Enter post code" autocomplete="off">
                            </div>

                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery'
    import Menu from './Menu.vue'

    export default {
        name: 'HelloWorld',
        data() {
            return {
                notFound: false,
                addresses: null,
                formData: {
                    first_name: null
                },
            }
        },
        components: {
            Menu,
        },
        methods: {
            addShipping(){
                $('#addShippingModal').modal('show');
            },
            edit(row){
                this.formData = row;
                $('#addShippingModal').modal('show');
            },
            addShippingAddress: function(e) {
                e.preventDefault();

                if (!this.formData.first_name) {
                    this.$refs.first_name.focus();
                    this.flashMessage.error({message: 'Name required'})
                    return false;
                }
                if (!this.formData.phone) {
                    this.$refs.phone.focus();
                    this.flashMessage.error({message: 'Mobile number required'})
                    return false;
                }
                if (!this.formData.address_1) {
                    this.$refs.address_1.focus();
                    this.flashMessage.error({message: 'Address required'})
                    return false;
                }
                if (!this.formData.city) {
                    this.$refs.city.focus();
                    this.flashMessage.error({message: 'City required'})
                    return false;
                }
                if (!this.formData.zip_code) {
                    this.$refs.zip_code.focus();
                    this.flashMessage.error({message: 'Post code required'})
                    return false;
                }
                if(this.formData.id){
                    this.updateShipping(this.formData);
                }else{
                    this.storeShipping(this.formData);
                }

            },
            storeShipping(formData) {
                this.$authHttp.post('customer-shipping-address', formData).then((response) => {
                    this.flashMessage.success({message: 'Address created successfully.'})
                    this.getShippingAddress();
                    $('#addShippingModal').modal('hide');
                    this.notFound = false;
                }).catch((e) => {
                    console.log(e);
                })
            },
            updateShipping(formData) {
                this.$authHttp.put('customer-shipping-address/'+formData.id, formData).then((response) => {
                    this.flashMessage.success({message: 'Address updated successfully.'})
                    this.getShippingAddress();
                    $('#addShippingModal').modal('hide');
                }).catch((e) => {
                    console.log(e);
                })
            },
            getShippingAddress() {
                this.$authHttp.get('customer-shipping-address').then((response) => {
                    this.addresses = response.data.data;
                    if(this.addresses.length < 1) this.notFound = true;
                }).catch((e) => {
                    console.log(e);
                    this.notFound = true;
                })
            },
        },
        mounted() {
            this.getShippingAddress();
        }
    }
</script>