<template>
  <div class="container details-page">
    <div class="breadcrumbs">
      <div class="row">
        <div class="col-12">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item"><a href="/shops">Shops</a></li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-12">
        <div class="shops">
          <router-link
            v-for="(row, key) in stores"
            v-bind:key="key"
            :to="{ name: 'ShopDetails', params: { slug: row.slug } }"
          >
            <div class="shops-container">
              <div class="px-2 overflow-hidden">
                <div class="shop-card">
                  <img
                    v-if="row.logo"
                    class="shopcard-image"
                    alt="Shop Logo"
                    :src="`${IMAGEURL}${row.logo}`"
                  />
                </div>
              </div>
              <div class="p-1">
                <div class="shopcard-title">{{ row.store_name }}</div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      layout: "grid",
      stores: null,
    };
  },
  methods: {
    getStores() {
      this.$http
        .get("vendor")
        .then((response) => {
          console.log(response);
          this.stores = response.data.data;
          this.storesLoading = false;
        })
        .catch((e) => {
          this.stores = [];
          this.storesLoading = true;
        });
    },
  },
  mounted() {
    this.getStores();
  },
};
</script>
