<template>
  <div class="container details-page">
    <div class="breadcrumbs">
      <div class="row">
        <div class="col-12 pr">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'Stores' }">Shops</router-link>
              </li>
              <li class="breadcrumb-item">{{ $route.params.slug }}</li>
            </ol>
          </nav>
          <div class="social-share">
            <ShareNetwork
              v-for="network in networks"
              :network="network.network"
              :key="network.network"
              :style="{ backgroundColor: network.color }"
              :url="sharing.url"
              :title="sharing.title"
              :description="sharing.description"
              :quote="sharing.quote"
              :hashtags="sharing.hashtags"
              :twitterUser="sharing.twitterUser"
            >
              <i :class="network.icon"></i>
              <!-- <button class="dropdown-item" type="button">{{ network.name }}</button> -->
            </ShareNetwork>
          </div>
        </div>
      </div>
    </div>

    <div class="row store-info">
      <div class="col-lg-12">
        <div class="bg-white shadow p-2">
          <div class="d-flex">
            <div class="my-auto mr-4" v-if="vendorDetails.logo">
              <img  :src="`${IMAGEURL}${vendorDetails.logo}`" alt="logo" />
            </div>
            <div class="p-2 my-auto">
              <div class="text-center text-sm-left">
                <h2 class="mb-0 font-bold mr-4">
                  {{ vendorDetails.store_name }}
                </h2>
                <p class="">
                  {{ vendorDetails.address1 }}
                </p>
                <p class="">
                  {{ vendorDetails.website }}
                </p>
                <p class="">
                  {{ vendorDetails.phone }}
                </p>
              </div>
            </div>
            <img
              v-if="vendorDetails.banner"
              :src="`${IMAGEURL}${vendorDetails.banner}`"
              alt="shop banner"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 d-none d-lg-block">
        <CategoryDetailsMenu></CategoryDetailsMenu>
      </div>
      <div class="col-12 col-lg-9">
        <!---section start--------->
        <!--  <section class="section py-0">
          <div class="">
            <div class="category-banner">
              <div class="no-image">{{$route.params.name}}</div>
            </div>
          </div>

        </section>-->

        <section class="category-items p-0">
          <div class="row grid">
            <a
              href="javascript:;"
              v-for="(item, key) in items"
              v-bind:key="key"
              @click="itemDetails(item)"
            >
              <div
                class="section-item d-flex rounded-lg justify-content-center flex-column"
              >
                <div class="item-image-holder">
                  <a href="javascript:;" @click="itemDetails(item)">
                    <img
                      :src="`${IMAGEURL}${item.thumb_image}`"
                      class="img-responsive"
                    />
                  </a>
                </div>
                <div class="pb-3 item-title">{{ item.name }}</div>
                <div class="item-price pb-2">
                  <span v-if="item.retail_status == 'Yes'"
                    >৳ {{ item.sales_price }}</span
                  >
                  <span v-else>৳ {{ item.wholesales_price }}</span>
                </div>
              </div>
            </a>
          </div>

          <div v-if="notFound">
            Sorry, but items not found belongs to this shop
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import CategoryDetailsMenu from "./CategoryDetailsMenu.vue";

export default {
  data() {
    return {
      layout: "grid",
      items: null,
      notFound: false,
      vendorDetails: null,
      sharing: {
        url:
          "https://bhairabshoemart.com/shop/" + "/" + this.$route.params.slug,
        title: "Bhairab Shoe Mart" || "",
        description: "Bhairab Shoe Mart",
        quote: "BhairabShoeMart",
        hashtags: "BhairabShoeMart",
        twitterUser: "",
      },
      networks: [
        {
          network: "facebook",
          name: "Facebook",
          icon: "fa fa-facebook",
          color: "#1877f2",
        },
        {
          network: "linkedin",
          name: "LinkedIn",
          icon: "fa fa-linkedin",
          color: "#007bb5",
        },
        // {
        //   network: "pinterest",
        //   name: "Pinterest",
        //   icon: "fa fa-pinterest",
        //   color: "#bd081c"
        // },
        {
          network: "twitter",
          name: "Twitter",
          icon: "fa fa-twitter",
          color: "#1da1f2",
        },
        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "fa fa-whatsapp",
          color: "#25d366",
        },
      ],
    };
  },
  components: {
    CategoryDetailsMenu,
  },
  methods: {
    itemDetails(item) {
      this.$router.push("/item/" + item.id + "/" + item.slug);
    },
    getStoreProducts() {
      this.$http
        .get("featured-vendor-product/" + this.$route.params.slug)
        .then((response) => {
          this.items = response.data.data;
          if (this.items.length < 1) this.notFound = true;
        })
        .catch((e) => {
          this.items = [];
          this.notFound = true;
        });
    },
    getVendorDetails() {
      this.$http
        .get("vendor/" + this.$route.params.slug)
        .then((response) => {
          this.vendorDetails = response.data.data;
        })
        .catch((e) => {
          this.vendorDetails = [];
        });
    },
  },
  mounted() {
    this.getStoreProducts();
    this.getVendorDetails();
  },
};
</script>
