var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container details-page"},[_vm._m(0),_c('section',{staticClass:"section",style:(_vm.cmsStyle)},[_c('div',{staticClass:"section-contents text-justify blog-detail"},[_c('div',{staticClass:"row blog-row"},_vm._l((_vm.dataRows),function(item,key){return _c('div',{key:key,staticClass:"col-lg-3 text-center"},[(item.custom_field1 == 1)?_c('div',[_c('a',{attrs:{"href":`${item.short_description}`,"target":"_blank"}},[_c('img',{staticClass:"img-responsive news-image",attrs:{"src":`${_vm.IMAGEURL}${item.image}`}})]),_c('a',{staticClass:"text-white",attrs:{"href":`${item.short_description}`,"target":"_blank"}},[_c('h4',[_vm._v(" "+_vm._s(item.title)+" ")])])]):_c('div',[_c('router-link',{attrs:{"to":{
                name: 'CMS',
                params: { id: item.id, slug: item.slug },
              }}},[_c('img',{staticClass:"img-responsive news-image",attrs:{"src":`${_vm.IMAGEURL}${item.image}`}})]),_c('router-link',{attrs:{"to":{
                name: 'CMS',
                params: { id: item.id, slug: item.slug },
              }}},[_c('h4',{staticClass:"text-white"},[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('div',{domProps:{"innerHTML":_vm._s(item.short_description)}})],1)])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"breadcrumbs"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('h2',[_vm._v("News & stories")])]),_c('div',{staticClass:"col-lg-auto ml-auto col-12"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"/"}},[_vm._v("Home")])]),_c('li',{staticClass:"breadcrumb-item"},[_vm._v("News & stories")])])])])])])
}]

export { render, staticRenderFns }