<template>
    <div class="container details-page">
        <div class="breadcrumbs">
            <div class="row">
                <div class="col-12">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                            <li class="breadcrumb-item">
                                <router-link :to="{ name: 'Orders'}">My Orders</router-link>
                            </li>
                            <li class="breadcrumb-item">Order Details</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>

        <section class="section">
            <div class="section-contents text-justify blog-detail">
                <div class="row justify-content-center">
                    <div class="col-md-10 text-center">
                        <h5>Order Number: {{orderDetails.order_no}}</h5>
                    </div>
                </div>
                <div class="row mt-4 justify-content-center">
                    <div class="col-md-5">
                        <div class="delivery-box text-center">
                            <h6 class="text-muted">Delivery Address</h6>
                            <p>{{orderDetails.shipping_address.address_1}}, {{orderDetails.shipping_address.city}},
                                {{orderDetails.shipping_address.zip_code}}</p>
                            <br/>
                            <br/>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="summary-box text-center">
                            <h6 class="text-muted">Order Summary</h6>
                            <div class="d-flex justify-content-between">
                                <div>Subtotal</div>
                                <div><span class="currency">৳</span> {{orderDetails.sub_total}}</div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div>Delivery Charge</div>
                                <div><span class="currency">৳</span> {{orderDetails.shipping_cost}}</div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div>Order Total</div>
                                <div><span class="currency">৳</span> {{orderDetails.grand_total}}</div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div class="text-danger font-weight-bold">Amount Paid</div>
                                <div><span class="currency">৳</span> 0</div>
                            </div>
                        </div>
                        <div class="due d-flex justify-content-between font-weight-bold">
                            <div>Due</div>
                            <div><span class="currency">৳</span> {{orderDetails.grand_total}}</div>
                        </div>
                    </div>
                </div>

                <div class="row mt-5 justify-content-center">
                    <div class="col-md-10">
                        <table class="table table-hover table-bordered">
                            <tr>
                                <th>Photo</th>
                                <th>Item</th>
                                <th class="text-right">Rate</th>
                                <th class="text-center">Qty</th>
                                <th class="text-right">Total</th>
                                <th class="text-right">Review</th>
                            </tr>
                            <tr v-for="(row, key) in orderDetails.order_to_product" v-bind:key="key">
                                <td><img v-if="row.product" :src="`${IMAGEURL}${row.product.thumb_image}`"
                                         class="img-responsive details-image"></td>
                                <td>
                                    <span v-if="row.product">{{row.product.name}}</span>

                                    <div class="text-muted">
                                        <span v-if="row.size" class="mr-2">Size: {{row.size}}</span>
                                        <span v-if="row.color">Color: {{row.color}}</span>
                                    </div>
                                </td>
                                <td class="text-right"><span class="currency">৳</span> {{row.price}}</td>
                                <td class="text-center">{{row.quantity}}</td>
                                <td class="text-right"><span class="currency">৳</span> {{row.total}}</td>
                                <td class="text-right">
                                    <router-link :to="{ name: 'OrderReview', params: { id: row.product_id, order_id: row.order_id  } }">
                Write Review
              </router-link>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>
<script>
    import $ from 'jquery'
    import {mapGetters} from 'vuex';

    export default {
        name: 'CheckOut',
        components: {},
        computed: {
            ...mapGetters([
                'cart',
                'itemsCount',
                'subTotal'
            ])
        },
        data() {
            return {
                orderDetails: null,
            };
        },
        mounted() {
            this.getOrderDetails();
        },
        methods: {
            getOrderDetails() {
                this.$authHttp.get('order-details/' + this.$route.params.id).then((response) => {
                    this.orderDetails = response.data.data;
                    console.log(this.orderDetails);
                }).catch((e) => {
                    console.log(e);
                })
            }
        }
    }
</script>
