var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container details-page"},[_c('Breadcrumbs',{attrs:{"title":"Photo Gallery"}}),_c('section',{staticClass:"section"},[_c('div',{staticClass:"section-contents text-justify blog-detail"},[_c('div',{staticClass:"row blog-row"},_vm._l((_vm.dataRows),function(item,key){return _c('div',{key:key,staticClass:"col-lg-3 text-center"},[_c('router-link',{attrs:{"to":{
              name: 'GalleryDetails',
              params: { id: item.id },
            }}},[_c('img',{staticClass:"img-responsive news-image",attrs:{"src":`${_vm.IMAGEURL}${item.image}`}})]),_c('router-link',{attrs:{"to":{
              name: 'GalleryDetails',
              params: { id: item.id },
            }}},[_c('h4',[_vm._v(" "+_vm._s(item.title)+" ")])])],1)}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }