<template>
  <div class="container details-page">
    <Breadcrumbs title="Photo Gallery"></Breadcrumbs>

    <section class="section">
      <div class="section-contents text-justify blog-detail grid">
        <a :href="`${IMAGEURL}${item.image}`" v-for="(item, index) in dataRows" :key="index" target="_blank"> 
        <div class="d-flex rounded-lg justify-content-center flex-column" >
            <img :src="`${IMAGEURL}${item.image}`" class="mb-2"/>
        </div>
        <div class="pb-3 item-title text-center">{{ item.title }}</div>
      </a>
      </div>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from "../../layouts/common/Breadcrumbs.vue";

export default {
  name: "HelloWorld",
  components: {
    Breadcrumbs,
  },
  data() {
    return {
      dataRows: [],
    };
  },
  methods: {
    getData() {
      this.$http
        .get("cms/Gallery-Images?ref=" + this.$route.params.id)
        .then((response) => {
          this.dataRows = response.data.data;
        })
        .catch((e) => {
          this.dataRows = [];
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
