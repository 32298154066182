<template>
    <div class="container details-page">
        <div class="row">
            <div class="col-lg-3 d-none d-lg-block">
                <Menu></Menu>
            </div>
            <div class="col-12 col-lg-9">
                <!---section start--------->
                <section class="section">
                    <div class="section-contents pt-1">
                        <div class="row">
                            <div class="col-lg-12 profile-details">
                                <h2>
                                    Change Password
                                </h2>
                                <form @submit="updatePassword">
                                    <div class="form-group">
                                        <label>Password<span class="required">&nbsp;*</span></label>
                                        <input type="password" ref="password" v-model="formData.password" class="form-control" placeholder="Enter password" autocomplete="off">
                                    </div>

                                    <div class="form-group">
                                        <label>Confirm Password<span class="required">&nbsp;*</span></label>
                                        <input type="password" ref="confirm_password" v-model="formData.confirm_password" class="form-control" placeholder="Enter confirm password" autocomplete="off">
                                    </div>

                                    <div class="form-group">
                                        <button type="submit" class="btn btn-primary">Update</button>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>

                </section>

            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery'
    import Menu from './Menu.vue'

    export default {
        name: 'HelloWorld',
        data() {
            return {
                addresses: null,
                formData: {
                    password: null
                },
            }
        },
        components: {
            Menu,
        },
        methods: {
            updatePassword: function (e) {
                e.preventDefault();

                if (!this.formData.password) {
                    this.$refs.password.focus();
                    this.flashMessage.error({message: 'Password required'})
                    return false;
                }
                if (!this.formData.confirm_password) {
                    this.$refs.confirm_password.focus();
                    this.flashMessage.error({message: 'Confirm password required'})
                    return false;
                }
                if (this.formData.password !== this.formData.confirm_password) {
                    this.$refs.confirm_password.focus();
                    this.flashMessage.error({message: 'Passwords do not match.'})
                    return false;
                }

                this.$authHttp.post('change-customer-password', this.formData).then((response) => {
                    this.formData = null;
                    this.flashMessage.success({message: 'Your password has been updated successfully.'})
                }).catch((e) => {
                    console.log(e);
                })
            }
        }
    }
</script>