<template>
  <div class="breadcrumbs">
    <div class="row">
      <div class="col-lg-6"><h2>{{ title }}</h2></div>
      <div class="col-lg-auto ml-auto col-12">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item" v-if="title == 'Photo Gallery'">
              <router-link :to="{ name: 'Gallery'}"
                    >{{ title }}</router-link>
            </li>
            <li class="breadcrumb-item" v-else>{{ title }}</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  }
}
</script>
