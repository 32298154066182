<template>
  <div class="container details-page">
    <div class="breadcrumbs">
      <div class="row">
        <div class="col-lg-6">
          <h2>Forgot Password</h2>
        </div>
        <div class="col-lg-auto ml-auto col-12">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item">Forgot Password</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
    <section class="section">
      <div class="section-contents">

        <div class="row justify-content-center">
          <div class="col-lg-4">
            <form method="post">
          
              <div class="">
                <div class="form-group">
                  <label>Email</label>
                  <input
                    class="form-control"
                    placeholder="Enter your email"
                    required
                  >
                </div>

                     <button
                  type="submit"
                  class="btn btn-primary"
                >Reset Password</button>
              </div>
         
            </form>
          </div>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      loginType: "mobile",
      loginData: {
        phone: null,
        password: null,
      },
    };
  },
  methods: {
    loginSubmit: function (e) {
      e.preventDefault();
      if (!this.loginData.mobile) {
        this.$refs.mobile.focus();
        this.flashMessage.error({
          message: "Mobile number required",
        });
        return false;
      }
      if (!this.loginData.password) {
        this.$refs.password.focus();
        this.flashMessage.error({
          message: "Password required",
        });
        return false;
      }

      this.$http
        .post("login", this.loginData)
        .then((response) => {
          console.log(response);
          if (response.data.token) {
            this.$cookies.set("token", response.data.token, 0);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("customer_id", response.data.user_info.id);
            localStorage.setItem("name", response.data.user_info.name);
            this.flashMessage.success({
              message: "You logged in successfully.",
            });
            if (this.cart.length > 0) {
              this.cartInfoUpdate(response.data.token);
            } else {
              if (this.redirectUrl) {
                location.href = this.redirectUrl;
              } else {
                location.reload();
              }
            }
          } else {
            this.flashMessage.error({
              message: "Invalid mobile or password",
            });
          }
        })
        .catch((e) => {
          this.flashMessage.error({
            message: "Invalid mobile or password",
          });
          console.log(e);
        });
    },
  },
};
</script>