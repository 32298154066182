<template>
    <div class="container details-page">
        <div class="row">
            <div class="col-lg-3 d-none d-lg-block">
                <Menu></Menu>
            </div>
            <div class="col-12 col-lg-9">
                <!---section start--------->
                <section class="section">
                    <div class="section-contents pt-1">
                        <div class="row">
                            <div class="col-lg-12 profile-details">
                                <h2>Your Orders</h2>
                                <div class="table-responsive">
                                    <table class="table table-striped table-hover">
                                        <thead>
                                        <tr>
                                            <th scope="col">Order No</th>
                                            <th scope="col" class="text-center">Date</th>
                                            <th scope="col" class="text-right">Sub Total</th>
                                            <th scope="col" class="text-right">Delivery Charge</th>
                                            <th scope="col" class="text-right">Grand Total</th>
                                            <th scope="col" class="text-center">Status</th>
                                            <th scope="col" width="10%" class="text-center">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(row, key) in orderList" v-bind:key="key">
                                            <th scope="row">
                                                <router-link :to="{ name: 'OrderDetails', params: { id: row.id }}">{{row.order_no}}</router-link>
                                            </th>
                                            <td class="text-center">{{row.order_date}}</td>
                                            <td class="text-right"><span class="currency">৳</span> {{row.sub_total}}</td>
                                            <td class="text-right"><span class="currency">৳</span> {{row.shipping_cost}}</td>
                                            <td class="text-right"><span class="currency">৳</span> {{row.grand_total}}</td>
                                            <td class="text-center">{{row.status}}</td>
                                            <td class="text-center">
                                                <router-link :to="{ name: 'OrderDetails', params: { id: row.id }}" class="btn btn-primary btn-xs">Details</router-link>
                                            </td>
                                        </tr>
                                        <tr v-if="noDataFound">
                                            <th scope="row" colspan="7">
                                                No data found
                                            </th>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

            </div>
        </div>
    </div>
</template>

<script>
    import Menu from './Menu.vue'

    export default {
        name: 'HelloWorld',
        data() {
            return {
                noDataFound: false,
                orderList: null,
            }
        },
        components: {
            Menu,
        },
        methods: {
            getMyOrders() {
                this.$authHttp.get('customer-order').then((response) => {
                    this.orderList = response.data.data;
                    if(this.orderList.length < 1) this.noDataFound = true;
                }).catch((e) => {
                    if(this.orderList.length < 1) this.noDataFound = true;
                })
            }
        },
        mounted(){
            this.getMyOrders();
        }
    }
</script>