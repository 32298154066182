<template>
  <div class="container details-page">
    <div class="breadcrumbs">
      <div class="row">
        <div class=" col-12">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item"><a href="/">Item</a></li>
              <li class="breadcrumb-item">{{ product.name }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 d-none d-lg-block">
        <CategoryDetailsMenu></CategoryDetailsMenu>
      </div>
      <div class="col-12 col-lg-9">
        <!---section start--------->
        <section class="section">
          <div class="section-contents">
            <div class="row">
              <div class="col-lg-6">
                <div class="big-image">
                  <image-magnifier :src="image"
                    :zoom-src="image"
                    width="400"
                    height="300"
                    zoom-width="400"
                    zoom-height="300"></image-magnifier>
                  <!-- <v-zoom
                    :img="`${IMAGEURL}${bigImg}`"
                    :width="width"
                    class="img-responsive"
                    :magnify="1"
                  ></v-zoom> -->
                </div>
                <div class="small-images">
                  <ul class="list-inline">
                    <li
                      v-for="(row, key) in product.product_images"
                      v-bind:key="key"
                      class="list-inline-item"
                    >
                      <a href="javascript:;">
                        <img
                          :src="`${IMAGEURL}${row.url}`"
                          @click="changeImg(row)"
                          class="img-responsive"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-6">
                <!-- <div class="item-code text-uppercase text-muted">Code: {{product.model}}</div> -->
                <div class="mb-3 title-big">{{ product.name }}</div>
                <div class="mb-1 item-code text-uppercase text-muted">
                  SKU: {{ product.model }}
                </div>

                <div class="mb-3 text-muted">
                  <span class=" text-uppercase ">Store:</span>
                  <router-link
                    class="font-weight-bold"
                    :to="{
                      name: 'ShopDetails',
                      params: { slug: product.vendor_slug },
                    }"
                  >
                    {{ product.store_name }}
                  </router-link>
                </div>
                <div class="price-row mb-2">
                  <div class="item-price" v-if="product.retail_status == 'Yes'">
                    Retails: <span class="currency">৳</span>
                    {{ product.sales_price }}
                    <span v-if="product.discount > 0" class="item-discount">
                      (
                      <del
                        ><span class="currency">৳</span
                        >{{ product.cost_price }}</del
                      >
                      <span class="save">Save ৳{{ product.discount }}</span>
                      )
                    </span>
                  </div>
                </div>

                <div
                  class="item-price mb-3"
                  v-if="
                    product.wholesale_status == 'Yes' &&
                      product.quantity >= product.wholesale_min_qty
                  "
                >
                  Wholesale: <span class="currency">৳</span>
                  {{ product.wholesales_price }}
                  <span v-if="product.discount > 0" class="item-discount">
                    (
                    <del
                      ><span class="currency">৳</span
                      >{{ product.wholesale_cost_price }}</del
                    >
                    <span class="save">Save ৳{{ product.discount }}</span>
                    )
                  </span>
                  <div class="mt-1 font-13 text-small text-muted">
                    (Wholesale Min Qty: {{ product.wholesale_min_qty }})
                  </div>
                </div>

                <div class="d-flex">
                  <div class="" v-if="product.product_sizes.length > 0">
                    <label for="" class="font-weight-bold text-muted"
                      >Size:</label
                    >
                    <label
                      class="mx-1 inline-radio pr"
                      v-for="(size, key) in product.product_sizes"
                      v-bind:key="key"
                    >
                      <input
                      v-model="size_id"
                      :checked="key===0 ? true: false"
                      @click="sizeSelected(size.id)"
                        type="radio"
                        class="radio size"
                        name="size"
                        :value="`${size.id}`"
                      />
                      <span>{{ size.name }} </span>
                    </label>
                  </div>
                </div>

                <div>
                  <div class="mb-2" v-if="product.product_colors.length > 0">
                    <label for="" class="font-weight-bold text-muted"
                      >Color:</label
                    >
                    <label
                      class="mx-1 inline-radio pr"
                      v-for="(color, key) in product.product_colors"
                      v-bind:key="key"
                    >
                      <input
                      v-model="color_id"
                      :checked="key===0 ? true: false"
                        type="radio"
                        class="radio color"
                        name="color"
                        :value="`${color.id}`"
                        @click="colorSelected(color.id)"
                      />
                      <span>{{ color.name }} </span>
                    </label>
                  </div>
                </div>
                <!--
                                <div class="qty-row my-3">
                                    <span>Quantity: </span>
                                    <span class="dec" @click="dec">-</span>
                                    <span class="qty">{{qty}}</span>
                                    <span class="inc" @click="inc">+</span>
                                </div>-->
                <div class="my-4">

                  <span v-if="stockQty">
                    <button
                    @click="addToCart(product)"
                    class="btn btn-primary btn-add-to-cart btn-sm"
                    v-if="
                      product.wholesale_status == 'Yes' ||
                        product.retail_status == 'Yes'
                    "
                  >
                    Add to Cart
                  </button>
                </span>
                <a
                v-else
                    href="javascript:;"
                    disabled
                    title="Out of Stock"
                    class="btn btn-info ml-1 btn-sm"
                    >Out of Stock</a
                  >

                  <a
                    href="javascript:;"
                    @click="addToWishlist(product)"
                    title="Add to Wishlist"
                    class="btn btn-info ml-1 btn-sm"
                    >Add to Wishlist</a
                  >
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="mt-3">
          <ul id="tabs" class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a
                id="tab-B"
                href="#pane-B"
                class="nav-link active"
                data-toggle="tab"
                role="tab"
                >Description</a
              >
            </li>
            <li class="nav-item">
              <a
                id="tab-C"
                href="#pane-C"
                class="nav-link"
                data-toggle="tab"
                role="tab"
                >Specification</a
              >
            </li>
            <li class="nav-item">
              <a
                id="tab-D"
                href="#pane-D"
                class="nav-link"
                data-toggle="tab"
                role="tab"
                >Reviews</a
              >
            </li>
          </ul>

          <div id="content" class="tab-content item-description" role="tablist">
            <div
              id="pane-B"
              class="card tab-pane fade active show"
              role="tabpanel"
              aria-labelledby="tab-B"
            >
              <div
                id="collapse-B"
                class="collapse show"
                data-parent="#content"
                role="tabpanel"
                aria-labelledby="heading-B"
              >
                <div class="card-body text-justify">
                  <div
                    v-if="product.description"
                    v-html="product.description"
                  ></div>
                  <div v-else>No data found</div>
                </div>
              </div>
            </div>

            <div
              id="pane-C"
              class="card tab-pane"
              role="tabpanel"
              aria-labelledby="tab-C"
            >
              <div
                id="collapse-C"
                class="collapse show"
                data-parent="#content"
                role="tabpanel"
                aria-labelledby="heading-C"
              >
                <div class="card-body text-justify">
                  <div
                    v-if="product.specification"
                    v-html="product.specification"
                  ></div>
                  <div v-else>No data found</div>
                </div>
              </div>
            </div>

            <div
              id="pane-D"
              class="card tab-pane"
              role="tabpanel"
              aria-labelledby="tab-D"
            >
              <div
                id="collapse-D"
                class="collapse show"
                data-parent="#content"
                role="tabpanel"
                aria-labelledby="heading-D"
              >
                <div class="text-justify">
                  <div v-if="productReviews">
                    <div
                      class=""
                      v-for="(review, key) in productReviews"
                      v-bind:key="key"
                    >
                      <div class="">
                        <div class="card-body">
                          <div>
                            <div class="d-flex justify-content-between">
                              <div>
                                <span
                                  v-for="index of review.rating"
                                  v-bind:key="index"
                                  ><i class="text-warning fa fa-star"></i
                                ></span>
                              </div>
                              <div>{{ getFormatedDate(review.created_at) }}</div>
                            </div>
                            <div>
                              <strong>{{ review.customer.name }} </strong>
                            </div>
                          </div>
                          <div class="clearfix"></div>
                          <div>
                            {{ review.comments }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>No reviews found</div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="mt-3">
          <header class="">
            <h2>Related Items</h2>
          </header>
          <div class="">
            <div class="row grid">
              <a
                href="#"
                v-for="(item, key) in relatedProduct"
                v-bind:key="key"
                @click="itemDetails(item)"
              >
                <div
                  class="section-item d-flex rounded-lg justify-content-center flex-column"
                >
                  <div class="item-image-holder">
                    <a href="javascript:;" @click="itemDetails(item)">
                      <img
                        :src="`${IMAGEURL}${item.thumb_image}`"
                        class="img-responsive"
                      />
                    </a>
                  </div>
                  <div class="pb-3 item-title">{{ item.name }}</div>
                  <div class="item-price pb-2">
                    <span class="currency">৳</span> {{ item.sales_price }}
                  </div>
                </div>
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import CategoryDetailsMenu from "./CategoryDetailsMenu.vue";
import moment from "moment";
import {ImageMagnifier} from "vue-image-magnifier";

export default {
  name: "HelloWorld",
  data() {
    return {
      size_id: 0,
      color_id: 0,
      stockQty: 0,
      product: {},
      relatedProduct: [],
      productReviews: [],
      qty: 1,
      bigImg: "",
      showMore: false,
      width: "auto",
      image: 'https://unpkg.com/vue-image-magnifier@0.1.1/example/img/DA2D9393-4081-4384-B493-95DA1620C26D.png'
    };
  },
  components: {
    CategoryDetailsMenu,
    ImageMagnifier,
  },
  methods: {
    colorSelected(id) {
      this.color_id = id;
      this.getItemQty();
    },
    sizeSelected(id) {
      this.size_id = id;
      this.getItemQty();
    },
    getFormatedDate: function(date) {
      return date ? moment(date, "YYYY-MM-DD").format("DD MMM YYYY") : '';
    },
    getItemQty() {
      this.$http
        .get(`product-qty?size_id=${this.size_id}&color_id=${this.color_id}&product_id=${this.$route.params.id}`)
        .then((response) => {
          if(response.data.qty > 0) {
            this.stockQty = response.data.qty;
          }else{
            this.stockQty = 0;
          }
        })
        .catch((e) => {
          console.log(e)
          this.stockQty = 0;
        });
    },
    getItemDetails() {
      console.log(this.$route.params.id);
      this.$http
        .get("product/" + this.$route.params.id)
        .then((response) => {
          console.log(response);
          this.product = response.data.data.product_details;
          this.relatedProduct = response.data.data.related_product;
          this.productReviews = response.data.data.productReviews;
          this.image = this.IMAGEURL + this.product.product_images[0].url;
          console.log(this.product);

          if (this.product.product_colors.length > 0) {
            this.color_id = this.product.product_colors[0].id;
            console.log(this.color_id);
          }
          if (this.product.product_sizes.length > 0) {
            this.size_id = this.product.product_sizes[0].id;
          }

          this.getItemQty();
        })
        .catch((e) => {
          this.product = {};
          this.relatedProduct = [];
          this.productReviews = [];
        });
    },
    itemDetails(item) {
      //alert(item.id)
      this.bigImg = '';
      this.$router.push("/item/" + item.id + "/" + item.slug);
      this.getItemDetails();
    },
    changeImg(row) {
      this.bigImg = row.url;
    },
    dec() {
      if (this.qty > 1) {
        this.qty = this.qty - 1;
      }
    },
    inc() {
      this.qty = this.qty + 1;
    },
    showMoreDetails() {
      this.showMore = true;
    },
    showLessDetails() {
      this.showMore = false;
    },
    async addToCart(row) {
      var size = $('input[name="size"]:checked').val();
      var color = $('input[name="color"]:checked').val();
      if (!size) {
        this.flashMessage.error({ message: "Please select size" });
        return false;
      }

      if (!color) {
        this.flashMessage.error({ message: "Please select color" });
        return false;
      }

      var quantity = 1;
      if (row.retail_status == "No") {
        quantity = row.wholesale_min_qty;
      }
      console.log(quantity);

      let itemObj = {
        session_id: localStorage.getItem("session_id"),
        product_id: row.id,
        quantity: quantity,
        size: size,
        color: color,
      };
      const response = await this.$store.dispatch("addToCart", itemObj);
      if(response.status === 'error'){
        this.flashMessage.error({ message: response.messages });
        return false;
      }
      $(".cart-details-container").addClass("cart-in");
    },
    addToWishlist(row) {
      if (!localStorage.getItem("customer_id")) {
        location.href = "/login?rd=item/" + row.id + "/" + row.slug;
      }
      let itemObj = {
        session_id: localStorage.getItem("session_id"),
        customer_id: localStorage.getItem("customer_id"),
        product_id: row.id,
        quantity: 1,
      };
      this.$store.dispatch("addToWishlist", itemObj);
    },
  },
  mounted() {
    this.getItemDetails();
    
  },
  watch: {
    $route(to, from) {
      this.show = false;
    },
  },
};
</script>
