<template>
  <div class="container details-page">
    <div class="breadcrumbs">
      <div class="row">
        <div class="col-12">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item">Sign Up</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
    <section class="section">
      <div class="section-contents">

        <div class="row justify-content-center">
          <div class="col-lg-4">
            <form @submit="submitRegistrationForm">

              <div class="">
                <div class="form-group">
                  <label>Full Name<span class="required">&nbsp;*</span></label>
                  <input
                    type="text"
                    ref="name"
                    v-model="registrationFormData.name"
                    class="form-control"
                    placeholder="Enter your full name"
                    autocomplete="off"
                  >
                </div>

                <label>Mobile<span class="required">&nbsp;*</span></label>
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">+88</span>
                  </div>
                  <input
                    type="text"
                    ref="mobile"
                    v-model="registrationFormData.mobile"
                    class="form-control"
                    placeholder="Enter your mobile number"
                    autocomplete="off"
                  >
                </div>

                <div class="form-group">
                  <label>Password<span class="required">&nbsp;*</span></label>
                  <input
                    type="password"
                    ref="password"
                    v-model="registrationFormData.password"
                    class="form-control"
                    placeholder="Enter password"
                    autocomplete="off"
                  >
                </div>

                <div class="form-group">
                  <label>Confirm Password<span class="required">&nbsp;*</span></label>
                  <input
                    type="password"
                    ref="confirm_password"
                    v-model="registrationFormData.confirm_password"
                    class="form-control"
                    placeholder="Enter confirm password"
                    autocomplete="off"
                  >
                </div>

                <button
                  type="submit"
                  class="btn btn-primary"
                >Register</button>

                <div class="mt-2">
                  <router-link :to="{ name: 'Login'}">Already have an account login here</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "HelloWorld",
  data() {
    return {
      registrationFormData: {
        name: "",
        mobile: "",
        password: "",
        confirm_password: "",
      },
    };
  },
  computed: {
        ...mapGetters([
            'cart',
            'wishlist',
            'itemsCount',
            'subTotal'
        ])
    },
  mounted(){
    if(localStorage.getItem("token")){
           this.flashMessage.success({
          message: "You are logged in already",
        });
      location.href = 'customer/profile'; 
    }
  },
  methods: {
    submitRegistrationForm: function (e) {
      e.preventDefault();
      if (!this.registrationFormData.name) {
        this.$refs.name.focus();
        this.flashMessage.error({
          message: "Name required",
        });
        return false;
      }
      if (!this.registrationFormData.mobile) {
        this.$refs.mobile.focus();
        this.flashMessage.error({
          message: "Mobile number required",
        });
        return false;
      }
      if (!this.registrationFormData.password) {
        this.$refs.password.focus();
        this.flashMessage.error({
          message: "Password required",
        });
        return false;
      }
      if (!this.registrationFormData.confirm_password) {
        this.$refs.confirm_password.focus();
        this.flashMessage.error({
          message: "Confirm password required",
        });
        return false;
      }
      if (
        this.registrationFormData.password !==
        this.registrationFormData.confirm_password
      ) {
        this.$refs.confirm_password.focus();
        this.flashMessage.error({
          message: "Passwords do not match.",
        });
        return false;
      }

      this.$http
        .post("customer", this.registrationFormData)
        .then((response) => {
          console.log(response.data);
          if (response.data.token) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("user_info", response.data.user_info);
            localStorage.setItem("customer_id", response.data.user_info.id);
            localStorage.setItem("name", response.data.user_info.name);
            this.flashMessage.success({
              message: "Congratulations! Your account created successfully.",
            });
            if (this.cart.length > 0) {
              this.cartInfoUpdate(response.data.token);
            } else {
              if (this.redirectUrl) {
                location.href = this.redirectUrl;
              } else {
                   location.href = 'customer/profile'; 
              }
            }
          } else {
            console.log(response.data);
            this.flashMessage.error({
              message: response.data.message,
            });
          }
        })
        .catch((e) => {
           this.flashMessage.error({
              message: "An error occured please try again later.",
            });
        });
    },
    cartInfoUpdate(token) {
        var data = {
            session_id: localStorage.getItem('session_id')
        }
        this.$http.post('cart-info-update', data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            if (this.$route.query.rd) {
                location.href = this.$route.query.rd;
            } else {
                location.reload();
            }
        }).catch((e) => {
            console.log(e);
        })
    },
  },
};
</script>