<template>
  <div class="container details-page">
    <div class="breadcrumbs">
      <div class="row">
        <div class="col-12">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item">
                <a href="/check-out">Check out</a>
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>

    <section class="section">
      <div class="section-contents text-justify blog-detail">
        <div class="row blog-row">
          <div class="col-md-8">
            <div id="accordion" class="delivery-address">
              <div class="card">
                <div class="card-header py-4" id="headingOne">
                  <h5 class="mb-0 collapsed d-flex justify-content-between">
                    <div class="text-left">
                      <i class="fa fa-map-marker pr-3"></i> Delivery Address
                    </div>
                    <div class="pt-1 flex-last-address">
                      <span class="last-address" v-if="selectedAddress"
                        >{{ selectedAddress.address_1 }},
                        {{ selectedAddress.city }},
                        {{ selectedAddress.zip_code }}</span
                      >
                      <span class="last-address" v-else
                        >You don't have delivery address.</span
                      >
                    </div>
                    <div
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      <div class="btn btn-outline-warning btn-xs text-right">
                        <span v-if="notFound">Add</span>
                        <span v-else>Change</span>
                      </div>
                    </div>
                  </h5>
                </div>

                <div
                  id="collapseOne"
                  class="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <button
                      v-for="(row, key) in addresses"
                      v-bind:key="key"
                      class="btn btn-outline-secondary btn-block cp"
                    >
                      <label
                        class="pull-left mr-1"
                        @click="selectDeliveryAddress(row)"
                        style="max-width: 85%;text-align: left;"
                      >
                        <input type="radio" name="delivery_address" />
                        {{ row.address_1 }}, {{ row.city }}, {{ row.zip_code }}
                      </label>
                      <span class="pull-right text-right">
                        <span
                          @click="edit(row)"
                          class="ml-3 btn btn-outline-info btn-xs"
                          >Edit</span
                        >
                      </span>
                    </button>

                    <button
                      class="btn btn-outline-secondary btn-sm btn-block"
                      @click="addDeliveryAddress"
                    >
                      <i class="fa fa-plus"></i> Add New Address
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <p class="text-right mt-4 mb-2 text-muted" v-if="shippingCost > 0">
              <span class="currency">৳</span> {{ shippingCost }} delivery charge
              included
            </p>
            <!--     <div class="card p-2">
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Promo code">
                                <div class="input-group-append">
                                    <button type="submit" class="btn btn-secondary">Apply</button>
                                </div>
                            </div>
                        </div>-->
            <div class="row ">
              <div class="col-lg-12 my-3">
                <div class=" payment-type text-right">
                  <span class="mr-3 font-weight-bold">পেমেন্ট টাইপ</span>
                  <label class="mr-2"
                    ><input
                      type="radio"
                      name="payment_type"
                      v-model="payment_type"
                      value="COD"
                    />
                    ক্যাশ অন ডেলিভারি</label
                  >
                  <label>
                    <input
                      type="radio"
                      name="payment_type"
                      v-model="payment_type"
                      value="SSL"
                    />
                    পে উইথ Visa/Master Card</label
                  >
                </div>
              </div>
            </div>

            <div class="row ">
              <div class="col-auto ml-auto">
                <div
                  class="btn-group  mt-2 mb-1 "
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    class="btn btn-info"
                    @click="placeOrder"
                    :disabled="isDisabled"
                  >
                    Place Order
                  </button>
                  <button type="button" class="btn btn-warning">
                    <span class="currency">৳</span> {{ subTotal }}
                  </button>
                </div>
              </div>
            </div>
            <p class="text-right">
              By click on place order you are agree with bhairab shoe mart
              <router-link :to="{ name: 'Terms' }"
                >Terms & Conditions</router-link
              >
            </p>
          </div>

          <div class="col-md-4 checkout-cart-details">
            <div class="card">
              <h4
                class="d-flex justify-content-between align-items-center mb-3 mt-2 card-header"
              >
                <span class="text-muted">Your cart</span>
                <span class="badge badge-secondary badge-pill">{{
                  cart.length
                }}</span>
              </h4>
              <ul class="list-group mb-3">
                <li
                  v-for="(row, key) in cart"
                  v-bind:key="key"
                  class="list-group-item lh-condensed"
                >
                  <div>
                    <h6 class="my-0">{{ row.product.name }}</h6>
                  </div>
                  <div class="text-muted">
                    <span v-if="row.size" class="my-0"
                      >Size: {{ row.size }}</span
                    >
                    <span v-if="row.color" class="my-0 ml-1"
                      >Color: {{ row.color }}</span
                    >
                  </div>
                  <div class="row mt-1">
                    <div class="col-lg-6">
                      <small class="text-muted"
                        ><span class="currency">৳</span> {{ row.price }} X
                        {{ row.quantity }}</small
                      >
                    </div>
                    <div class="text-right col-lg-6">
                      <span class="text-muted"
                        ><span class="currency">৳</span> {{ row.total }}</span
                      >
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="modal" id="addDelivery" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <form method="post" @submit="addShippingAddress">
            <div class="modal-header">
              <h5 class="modal-title">Add New Address</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body ">
              <div class="form-group">
                <label>Full Name<span class="required">&nbsp;*</span></label>
                <input
                  type="text"
                  ref="first_name"
                  v-model="formData.first_name"
                  class="form-control"
                  placeholder="Enter full name"
                  autocomplete="off"
                />
              </div>

              <div class="form-group">
                <label>Mobile<span class="required">&nbsp;*</span></label>
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">+88</span>
                  </div>
                  <input
                    type="text"
                    ref="phone"
                    v-model="formData.phone"
                    class="form-control"
                    placeholder="Enter your mobile number"
                    autocomplete="off"
                  />
                </div>
              </div>

              <div class="form-group">
                <label>Address<span class="required">&nbsp;*</span></label>
                <input
                  type="text"
                  ref="address_1"
                  v-model="formData.address_1"
                  class="form-control"
                  placeholder="Enter address"
                  autocomplete="off"
                />
              </div>

              <div class="form-group">
                <label>City<span class="required">&nbsp;*</span></label>
                <input
                  type="text"
                  ref="city"
                  v-model="formData.city"
                  class="form-control"
                  placeholder="Enter city"
                  autocomplete="off"
                />
              </div>

              <div class="form-group">
                <label>Post Code<span class="required">&nbsp;*</span></label>
                <input
                  type="text"
                  ref="zip_code"
                  v-model="formData.zip_code"
                  class="form-control"
                  placeholder="Enter post code"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" class="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { mapGetters } from "vuex";

export default {
  name: "CheckOut",
  components: {},
  computed: {
    ...mapGetters(["cart", "itemsCount", "subTotal"]),
  },
  data() {
    return {
      payment_type: 'COD',
      isDisabled: false,
      notFound: false,
      addresses: null,
      selectedAddress: null,
      shippingCost: null,
      formData: {
        first_name: "",
      },
      redirectUrl: "",
      order: {
        customer_id: localStorage.getItem("customer_id"),
        shipping_address_id: null,
        session_id: localStorage.getItem("session_id"),
      },
    };
  },
  beforeCreate() {
    this.$store.dispatch("getShipping", this.$authHttp);
  },
  mounted() {
    this.getProfile();
    this.calculateShippingCost();
    this.getShippingAddress();
    this.getLastDeliveryAddress();
    $(".cart-details-container").removeClass("cart-in");
    $(document).ready(function() {
      $("#outsideDiv").hide();
      $("#shippingOutsideDiv").hide();

      $("#outsideDhaka").change(function() {
        if (this.checked) {
          $("#outsideDiv").show();
        }
      });
      $("#insideDhaka").change(function() {
        if (this.checked) {
          $("#outsideDiv").hide();
        }
      });

      $("#shippingOutsideDhaka").change(function() {
        if (this.checked) {
          $("#shippingOutsideDiv").show();
        }
      });
      $("#shippingInsideDhaka").change(function() {
        if (this.checked) {
          $("#shippingOutsideDiv").hide();
        }
      });
    });
  },
  methods: {
    selectDeliveryAddress(row) {
      this.selectedAddress = row;
    },
    addDeliveryAddress() {
      $("#addDelivery").modal("show");
    },
    getLastDeliveryAddress() {
      this.$authHttp
        .get("customer-order-address")
        .then((response) => {
          if (response.data.data.id) {
            this.selectedAddress = response.data.data;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getProfile() {
      this.$authHttp
        .get("customer-profile")
        .then((response) => {
          this.billingAddressFormData = response.data.data;
          console.log(response.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    placeOrder() {
      this.isDisabled = true;
      if (!this.selectedAddress || !this.selectedAddress.id) {
        this.flashMessage.error({
          message: "Please select a delivery address.",
        });
        return false;
      }
      this.order.shipping_address_id = this.selectedAddress.id;
      this.order.payment_type = this.payment_type;

      this.$authHttp
        .post("order", this.order)
        .then((response) => {
          if (response.data.data.id && this.payment_type == 'COD') {
            location.href = "order/" + response.data.data.id;
          } else if (response.data.data.id && this.payment_type == 'SSL') {
              let redirect_to = process.env.VUE_APP_BASEURL;
              let orderId = response.data.data.id;
              location.href = process.env.VUE_APP_DOMAIN+'pay-now/'+orderId+'?redirect_to='+redirect_to;
          }else{
            this.submitted = false;
            this.flashMessage.error({ message: "একটি ত্রুটি ঘটেছে, পরে আবার চেষ্টা করুন" });
            return false;
          }

          console.log(response);
        })
        .catch((e) => {
          console.log(e);
          this.isDisabled = false;
        });
    },
    addShippingAddress: function(e) {
      e.preventDefault();

      if (!this.formData.first_name) {
        this.$refs.first_name.focus();
        this.flashMessage.error({ message: "Name required" });
        return false;
      }
      if (!this.formData.phone) {
        this.$refs.phone.focus();
        this.flashMessage.error({ message: "Mobile number required" });
        return false;
      }
      if (!this.formData.address_1) {
        this.$refs.address_1.focus();
        this.flashMessage.error({ message: "Address required" });
        return false;
      }
      if (!this.formData.city) {
        this.$refs.city.focus();
        this.flashMessage.error({ message: "City required" });
        return false;
      }
      if (!this.formData.zip_code) {
        this.$refs.zip_code.focus();
        this.flashMessage.error({ message: "Post code required" });
        return false;
      }
      if (this.formData.id) {
        this.updateShipping(this.formData);
      } else {
        this.storeShipping(this.formData);
      }
    },
    storeShipping(formData) {
      this.$authHttp
        .post("customer-shipping-address", formData)
        .then((response) => {
          this.flashMessage.success({
            message: "Address created successfully.",
          });
          this.getShippingAddress();
          $("#addDelivery").modal("hide");
          this.formData = { first_name: "" };
        })
        .catch((e) => {
          console.log(e);
        });
    },
    edit(row) {
      this.formData = row;
      $("#addDelivery").modal("show");
    },
    updateShipping(formData) {
      this.$authHttp
        .put("customer-shipping-address/" + formData.id, formData)
        .then((response) => {
          this.flashMessage.success({
            message: "Address updated successfully.",
          });
          this.getShippingAddress();
          $("#addDelivery").modal("hide");
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getShippingAddress() {
      this.$authHttp
        .get("customer-shipping-address")
        .then((response) => {
          this.addresses = response.data.data;
          if (this.addresses.length < 1) this.notFound = true;
        })
        .catch((e) => {
          console.log(e);
          this.notFound = true;
        });
    },
    calculateShippingCost() {
      this.$authHttp
        .get("calculateShippingCost/" + localStorage.getItem("customer_id"))
        .then((response) => {
          this.shippingCost = response.data.amount;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
