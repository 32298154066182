import axios from 'axios'

const state = {
    cart: [],
    wishlist: [],
    shipping: 0
};

const getters = {
    shipping: state => state.shipping,
    cart: state => state.cart,
    wishlist: state => state.wishlist,
    itemsCount(state) {
        if (state.cart) {
            return state.cart.length;
        } else {
            return 0;
        }
    },
    subTotal: state => {
        let sum = 0;
        state.cart.forEach(row => {
            sum += parseFloat(row.total);
        });
        //adding delivery charge
        sum = sum + state.shipping;
        console.log(state.shipping);
        return Math.ceil(sum).toFixed(2);
    }
};

const actions = {
    getShipping({commit, state}, payload) {
        payload.get(process.env.VUE_APP_APIURL+'calculateShippingCost/' + localStorage.getItem('customer_id'))
            .then(r => r.data)
            .then(response => {
                state.shipping = response.amount;
            })
    },    
    getCart({commit, state}) {
        axios.get(process.env.VUE_APP_APIURL+'cart/' + localStorage.getItem('session_id'))
            .then(r => r.data)
            .then(response => {
                state.cart = response.data;
            })
    },
    deleteFromCart({commit}, payload) {
        axios.delete(process.env.VUE_APP_APIURL+'cart/' + payload.id)
            .then(r => r.data)
            .then(response => {
                this.dispatch('getCart');
            })
    },
    async addToCart({commit}, payload) {
        try {
            const response = await axios.post(process.env.VUE_APP_APIURL+'cart', payload);
            this.dispatch('getCart');

            return response.data
        } catch (error) {
            return error.response.data
        }
    },
    async removeItemQty({commit}, payload) {
        try {
            const response = await axios.get(process.env.VUE_APP_APIURL+'removeItemQty/' + payload.id);
            this.dispatch('getCart');

            return response.data
        } catch (error) {
            return error.response.data
        }
    },
    async addItemQty({commit}, payload) {
        try {
            const response = await axios.get(process.env.VUE_APP_APIURL+'addItemQty/' + payload.id)
            this.dispatch('getCart');

            return response.data
        } catch (error) {
            return error.response.data
        }
    },
    getWishlist({commit, state}) {
        axios.get(process.env.VUE_APP_APIURL+'wishlist', {headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}})
            .then(r => r.data)
            .then(response => {
                console.log(response.data);
                state.wishlist = response.data;
            })
    },
    deleteFromWishlist({commit}, payload) {
        axios.delete(process.env.VUE_APP_APIURL+'wishlist/' + payload.id, {headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}})
            .then(r => r.data)
            .then(response => {
                this.dispatch('getWishlist');
            })
    },
    addToWishlist({commit}, payload) {
        axios.post(process.env.VUE_APP_APIURL+'wishlist', payload, {headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}}).then((response) => {
            this.dispatch('getWishlist');
        }).catch((e) => {
            console.log(e)
        })
    }
};

const mutations = {
    /*    addItemToCart: (state, value) => {
            if (!_.some(state.cart, {itemId: 1})) {
                state.cart.push(value);
            }
        }*/
};

export default {
    state,
    getters,
    actions,
    mutations
}