<template>
  <div>
    <div class="tree-category">
      <div class="title">Category</div>
      <ul>
        <li v-for="(row, key) in category" :key="key">
          <div class="link">
            <router-link
              :to="{ name: 'Category', params: { id: row.id, name: row.slug } }"
            >
              <span>{{ row.name }}</span>
            </router-link>
            <div v-if="row.childs" class="more">
              <i
                class="fa fa-chevron-down square-icon"
                @click="showSub(row.id)"
              ></i>
            </div>
          </div>

          <div v-if="row.childs" :class="`second-label none sub-${row.id}`">
            <ul>
              <li v-for="(srow, skey) in row.childs" :key="skey">
                <div class="link">
                  <router-link
                    :to="{
                      name: 'Category',
                      params: { id: srow.id, name: srow.slug },
                    }"
                  >
                    <span>{{ srow.name }}</span>
                  </router-link>
                  <div class="more" v-if="srow.childs">
                    <i
                      class="fa fa-plus plus-icon"
                      @click="showSub(srow.id)"
                    ></i>
                  </div>
                </div>

                <div
                  v-if="srow.childs"
                  :class="`third-label none sub-${srow.id}`"
                >
                  <ul>
                    <li v-for="(ssrow, sskey) in srow.childs" :key="sskey">
                      <div class="link">
                        <router-link
                          :to="{
                            name: 'Category',
                            params: { id: ssrow.id, name: ssrow.slug },
                          }"
                        >
                          <span>{{ ssrow.name }}</span>
                        </router-link>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>

      <div class="title">Brand</div>
      <div class="filter-section brand">
        <ul>
          <li
            v-for="store in stores"
            :key="store.id"
            :data-id="store.id"
            class="checkbox store"
            @click="addToFilter('store')"
          >
            <input
              type="checkbox"
              :id="`store${store.id}`"
              :value="`${store.id}`"
              v-model="checkedStore"
            />
            <label :for="`store${store.id}`">{{ store.store_name }}</label>
          </li>
        </ul>
      </div>

      <div class="title">Colour</div>
      <div class="filter-section">
        <ul>
          <li
            v-for="color in colors"
            :key="color.id"
            :data-id="color.id"
            class="checkbox color"
            @click="addToFilter('color')"
          >
            <input
              type="checkbox"
              :id="`color${color.id}`"
              :value="`${color.id}`"
              v-model="checkedColor"
            />
            <label :for="`color${color.id}`">{{ color.name }}</label>
          </li>
        </ul>
      </div>

      <div class="title">Price</div>
      <div class="filter-section mb-5 pb-3">
        <ul>
          <li class="checkbox">
            <input
              @click="setPrice(300, 500)"
              type="radio"
              id="priceslot1"
              v-model="checkedRange"
              value="300-500"
            />
            <label for="priceslot1">300-500 টাকা</label>
          </li>
          <li class="checkbox">
            <input
              @click="setPrice(500, 700)"
              type="radio"
              id="priceslot2"
              v-model="checkedRange"
              value="500-700"
            />
            <label for="priceslot2">500–700 টাকা</label>
          </li>
          <li class="checkbox">
            <input
              @click="setPrice(700, 1000)"
              type="radio"
              id="priceslot3"
              v-model="checkedRange"
              value="700-1000"
            />
            <label for="priceslot2">700–1000 টাকা</label>
          </li>
          <li class="checkbox">
            <input
              @click="setPrice(1000, 1200)"
              type="radio"
              id="priceslot4"
              v-model="checkedRange"
              value="1000-1200"
            />
            <label for="priceslot4">1000-1200 টাকা</label>
          </li>
          <li class="checkbox">
            <input
              @click="setPrice(1200, 1500)"
              type="radio"
              id="priceslot5"
              v-model="checkedRange"
              value="1200-1500"
            />
            <label for="priceslot5">1200-1500 টাকা</label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      category: null,
      colors: [],
      stores: [],
      checkedColor: [],
      checkedStore: [],
      checkedRange: "",
    };
  },
  methods: {
    addToFilter(type) {
      var ids = [];
      $("." + type).each(function() {
        let id = $(this).data("id");
        if ($("#" + type + id).is(":checked")) {
          ids.push(id);
        }
      });
      let url = this.$helper.UrlGenerate(type, ids);
      window.location.href = url;
    },
    setPrice(min, max) {
      let url = this.$helper.UrlGenerate("priceRange", min + "-" + max);
      window.location.href = url;
    },
    showSub(id) {
      $(".sub-" + id).fadeToggle();
    },
    getCategoryList() {
      this.$http
        .get("category")
        .then((response) => {
          this.category = response.data.data;
        })
        .catch((e) => {
          this.category = [];
        });
    },
    getColors() {
      this.$http
        .get("color")
        .then((response) => {
          this.colors = response.data;
          console.log({ response });
        })
        .catch((e) => {
          this.colors = [];
        });
    },
    getStores() {
      this.$http
        .get("vendor")
        .then((response) => {
          console.log(response);
          this.stores = response.data.data;
        })
        .catch((e) => {
          this.stores = [];
        });
    },
  },
  mounted() {
    this.getCategoryList();
    this.getColors();
    this.getStores();

    let store = this.$route.query.store;
    this.checkedStore = store? store.split(",") : [];

    let color = this.$route.query.color;
    this.checkedColor = color? color.split(",") : [];

    this.checkedRange = this.$route.query.priceRange? this.$route.query.priceRange: "";
    if (this.checkedRange) {
      let range = this.checkedRange.split("-");
      this.priceRangeMin = range[0];
      this.priceRangeMax = range[1];
    }
  },
};
</script>

<style scoped></style>
