<template>
    <div class="container details-page">
        <div class="row">
            <div class="col-lg-3 d-none d-lg-block">
                <Menu></Menu>
            </div>
            <div class="col-12 col-lg-9">
                <!---section start--------->
                <section class="section">
                    <div class="section-contents pt-1">
                        <div class="row">
                            <div class="col-lg-12 profile-details">
                                <h2>Your Profile <span class="float-right mr-points">MR Points: <span class="point">{{ mrPoints }}</span></span></h2>

                                <form @submit="updateProfile">

                                    <div class="form-group">
                                        <label>Name<span class="required">*</span></label>
                                        <input type="text" ref="name" v-model="formData.name" class="form-control" value="Md. Minul Islam"/>
                                    </div>

                                    <div class="form-group">
                                        <label>Mobile<span class="required">*</span></label>
                                        <div class="input-group mb-2">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">+88</span>
                                            </div>
                                            <input type="text" ref="mobile" v-model="formData.mobile" class="form-control" placeholder="Enter your mobile number" autocomplete="off">
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label>Email</label>
                                        <input type="email" ref="email" v-model="formData.email" class="form-control" value="e.g. engrminul@gmail.com"/>
                                    </div>

                                    <div class="form-group">
                                        <button type="submit" class="btn btn-primary">Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </section>

            </div>
        </div>
    </div>
</template>

<script>
    import Menu from './Menu.vue'

    export default {
        name: 'HelloWorld',
        data() {
            return {
                formData: {
                    name: null
                },
                mrPoints: 0
            }
        },
        components: {
            Menu,
        },
        methods: {
            getProfile() {
                this.$authHttp.get('customer-profile').then((response) => {
                    console.log(response);
                    this.formData = response.data.data;
                    this.mrPoints = response.data.mrPoints;
                }).catch((e) => {
                    console.log(e);
                })
            },
            updateProfile: function (e) {
                e.preventDefault();
                if (!this.formData.name) {
                    this.$refs.name.focus();
                    this.flashMessage.error({message: 'Name required'})
                    return false;
                }
                if (!this.formData.mobile) {
                    this.$refs.mobile.focus();
                    this.flashMessage.error({message: 'Mobile number required'})
                    return false;
                }

                this.$authHttp.post('customer-profile-update', this.formData).then((response) => {
                    this.flashMessage.success({message: 'Profile has been updated successfully.'})
                }).catch((e) => {
                    this.flashMessage.error({message: 'Customer already exist with same mobile number.'})
                })
            }
        },
        mounted() {
            this.getProfile();
        }
    }
</script>