<template>
    <div class="container details-page blog-detail-page">
        <nav aria-label="breadcrumb" class="breadcrumbs">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/">Home</a></li>
                <li class="breadcrumb-item"><a href="/blog">Blog</a></li>
                <li class="breadcrumb-item">Lorem ipsum dolor consectetur adipiscing</li>
            </ol>
        </nav>
        <section class="section">
            <div class="section-contents text-justify blog-detail">
                <div class="row">
                    <div class="col-lg-12">
                        <img src="/img/slider/slider2.webp" class="img-responsive">

                        <h2>Also the leap into electronic typesetting, remaining essentially unchanged.</h2>
                        <ul class="post-social">
                            <li>25 January 2018</li>
                            <li>Posted By : Admin Admin</li>
                            <li><i class="fa fa-heart"></i> 5 Hits
                            </li>
                            <li><i class="fa fa-comments"></i> 10 Comment
                            </li>
                        </ul>

                        <p>Numquam, habitant minim leo magnam mollitia quibusdam proident rhoncus! Provident quo? Aperiam. Etiam bibendum molestie augue irure fames justo occaecati? Accusantium sint massa sociosqu amet necessitatibus. Facilisis. Eiusmod, pede et congue? Blanditiis adipisci nostrum
                            parturient integer, dis et? Id, maecenas eos sit reprehenderit in augue. Viverra habitant vulputate? Totam aliqua quasi aperiam veritatis natoque, donec! Ea dapibus laudantium elit aenean. Dolor delectus! Donec, molestie esse senectus? Asperiores eu commodo? Minim,
                            habitant veritatis, veritatis ipsam morbi, porta occaecat! Suspendisse interdum, rhoncus natoque, mi consequatur sequi rerum ipsum habitant eu proident dictumst sem suspendisse luctus ab odit eum? Sequi voluptatum! Sed tristique.</p>

                        <p>Error. Feugiat nostrum reprehenderit hac libero quae eget magnam sunt nonummy neque, inceptos sed. Volutpat voluptates sem, erat mattis hic? Dictum nostrum exercitationem necessitatibus corrupti placeat voluptatem, ad. Dictumst tenetur autem voluptatum. Quae, ducimus in,
                            mauris, repellat volutpat, ullamcorper. Augue fusce magnam nullam dis quo perspiciatis tortor nunc. Nobis incidunt sociosqu quasi integer at accusamus. Quos, quo dictum dapibus suscipit. Sagittis faucibus justo nihil unde varius, ipsa conubia, rerum placerat nesciunt
                            morbi. Sapiente nostra at natus elit, maecenas? Posuere commodi, quae voluptate occaecati magna ducimus rutrum dis ac voluptatum voluptates, inventore ligula est enim veritatis! Rhoncus atque accumsan? Irure? Earum.</p>

                        <p>Incidunt occaecat venenatis doloribus nostrum esse pharetra nibh rhoncus tincidunt fringilla dignissimos, eaque sodales sagittis aptent itaque primis? Quisquam mattis, consequat ac. Rerum iste, sapien? Turpis cras habitant. Purus aspernatur, quis semper provident
                            adipiscing. Voluptate, lectus pharetra laborum, risus morbi ea illum. Ultrices perferendis consequuntur, delectus, augue aliquid nam integer, non, veritatis, debitis laudantium ab feugiat, metus quam quae aperiam! Feugiat saepe accumsan! Auctor laoreet accusantium. Rutrum
                            distinctio! Repellendus harum senectus iusto, cupidatat anim senectus mollitia officia ullam a debitis harum suscipit voluptatem dolorem excepturi wisi, ridiculus autem. Elementum distinctio, orci magnam! Erat provident incididunt, ullam! Ad recusandae assumenda,
                            autem.</p>

                    </div>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="section-contents">
                <div class="row">
                    <div class="col-sm-12">
                        <ul class="comment-section">
                            <li>
                                <div class="media">
                                    <img src="/img/user/1.jpg" alt="Generic placeholder image">
                                    <div class="media-body">
                                        <h6>Mark Jecno<span>( 12 Jannuary 2018 at 1:30AM )</span></h6>
                                        <p>Donec rhoncus massa quis nibh imperdiet dictum. Vestibulum id est sit amet felis fringilla bibendum at at leo. Proin molestie ac nisi eu laoreet. Integer faucibus enim nec ullamcorper tempor. Aenean nec felis dui. Integer tristique odio mi, in volutpat
                                            metus
                                            posuere eu. Aenean suscipit ipsum nunc, id volutpat lorem hendrerit ac. Sed id elit quam. In ac mauris arcu. Praesent eget lectus sit amet diam vestibulum varius. Suspendisse dignissim mattis leo, nec facilisis erat tempor quis. Vestibulum eu vestibulum
                                            ex.</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="media">
                                    <img src="/img/user/2.jpg" alt="Generic placeholder image">
                                    <div class="media-body">
                                        <h6>Mark Jecno<span>( 12 Jannuary 2018 at 1:30AM )</span></h6>
                                        <p>
                                            Donec rhoncus massa quis nibh imperdiet dictum. Vestibulum id est sit amet felis fringilla bibendum at at leo. Proin molestie ac nisi eu laoreet. Integer faucibus enim nec ullamcorper tempor. Aenean nec felis dui. Integer tristique odio mi, in volutpat
                                            metus
                                            posuere eu. Aenean suscipit ipsum nunc, id volutpat lorem hendrerit ac. Sed id elit quam. In ac mauris arcu. Praesent eget lectus sit amet diam vestibulum varius. Suspendisse dignissim mattis leo, nec facilisis erat tempor quis. Vestibulum eu vestibulum
                                            ex.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="media">
                                    <img src="/img/user/3.jpg" alt="Generic placeholder image">
                                    <div class="media-body">
                                        <h6>Mark Jecno<span>( 12 Jannuary 2018 at 1:30AM )</span></h6>
                                        <p>
                                            Donec rhoncus massa quis nibh imperdiet dictum. Vestibulum id est sit amet felis fringilla bibendum at at leo. Proin molestie ac nisi eu laoreet. Integer faucibus enim nec ullamcorper tempor. Aenean nec felis dui. Integer tristique odio mi, in volutpat
                                            metus
                                            posuere eu. Aenean suscipit ipsum nunc, id volutpat lorem hendrerit ac. Sed id elit quam. In ac mauris arcu. Praesent eget lectus sit amet diam vestibulum varius. Suspendisse dignissim mattis leo, nec facilisis erat tempor quis. Vestibulum eu vestibulum
                                            ex.
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="section-contents">
                <div class="row blog-contact">
                    <div class="col-sm-12">
                        <h2>Leave Your Comment</h2>
                        <form class="theme-form">
                            <div class="form-row">
                                <div class="col-md-12 form-group">
                                    <label for="name">Name</label>
                                    <input type="text" id="name" placeholder="Enter Your name" required="required" class="form-control">
                                </div>
                                <div class="col-md-12 form-group">
                                    <label for="email">Email</label>
                                    <input type="text" id="email" placeholder="Email" required="required" class="form-control">
                                </div>
                                <div class="col-md-12 form-group">
                                    <label for="exampleFormControlTextarea1">Comment</label>
                                    <textarea placeholder="Write Your Comment" id="exampleFormControlTextarea1" rows="6" class="form-control"></textarea>
                                </div>
                                <div class="col-md-12 form-group">
                                    <button type="submit" class="btn btn-primary">Post Comment</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import $ from 'jquery'
    export default {
        name: 'HelloWorld',
        data() {
            return {
                qty: 1,
                bigImg: 'item12.jpg',
                showMore: false,
                width: 'auto',
            }
        },
        methods: {
            itemDetails(id) {
                this.$router.push('/item/' + id);
            },
            changeImg(img) {
                this.bigImg = img;
            },
            dec() {
                if (this.qty > 1) {
                    this.qty = this.qty - 1;
                }
            },
            inc() {
                this.qty = this.qty + 1;
            },
            showMoreDetails() {
                this.showMore = true;
            },
            showLessDetails() {
                this.showMore = false;
            },
            addToCart(itemId) {
                let itemObj = {
                    itemId: itemId,
                    itemName: 'HP 26A Black LaserJet Toner Cartridge (Original)',
                    itemPrice: 3000,
                    itemImage: 'item12.jpg',
                    qty: 1,
                    amount: 3000,
                }
                $('.cart-details-container').addClass('cart-in');
                this.$store.commit("addItemToCart", itemObj);
            }
        }
    }
</script>