<template>
    <div class="container details-page">
        <div class="breadcrumbs">
            <div class="row">
                <div class="col-lg-6"><h2>Term of Use</h2></div>
                <div class="col-lg-auto ml-auto col-12">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link :to="{name: 'Home'}">Home</router-link></li>
                            <li class="breadcrumb-item">Term of Use</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
        <section class="section">
            <div class="section-contents text-justify">
                <div v-html="dataRow.description"></div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'HelloWorld',
        data() {
            return {
                dataRow: null,
            }
        },
        methods: {
            getData() {
                this.$authHttp.get('cms/Term-Of-Use').then((response) => {
                    this.dataRow = response.data.data;
                }).catch((e) => {
                    console.log(e);
                })
            }
        },
        mounted() {
            this.getData();
        },
    }
</script>