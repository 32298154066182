export default {
    validateMobileNo(mobileNo) {
        let prefixEntered = mobileNo.substring(0, 3);
        const validPrefix = [
            "011",
            "012",
            "013",
            "014",
            "015",
            "016",
            "017",
            "018",
            "019"
        ];
        let status = validPrefix.includes(prefixEntered);
        if (!status || mobileNo.length !== 11) {
            return false;
        } else {
            return true;
        }
    },
    UrlGenerate(query, ids){
		var url = new URL(window.location.href);
		var search_params = url.searchParams;
		// new value of "id" is set to "101"
		search_params.set(query, ids);
		// change the search property of the main url
		url.search = search_params.toString();
		// the new url string
		var new_url = url.toString();
		// output : http://demourl.com/path?id=101&topic=main
		console.log(new_url);
        return new_url;
		//window.location.href = new_url;
	},    
    removeURLParameter(parameter){
        let url = window.location.href;
        //prefer to use l.search if you have a location/link object
        var urlparts= url.split('?');   
        if (urlparts.length>=2) {

            var prefix= encodeURIComponent(parameter)+'=';
            var pars= urlparts[1].split(/[&;]/g);

            //reverse iteration as may be destructive
            for (var i= pars.length; i-- > 0;) {    
                //idiom for string.startsWith
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {  
                    pars.splice(i, 1);
                }
            }

            url= urlparts[0]+'?'+pars.join('&');
            return url;
        } else {
            return url;
        }
	},
    goTo(whereTo) {
        if(whereTo){
            window.location.href = '/'+whereTo;
        }
    },    

    engNTbn(engNumber) {
        if(engNumber){
            var numbers = {
                '0': '০',
                '1': '১',
                '2': '২',
                '3': '৩',
                '4': '৪',
                '5': '৫',
                '6': '৬',
                '7': '৭',
                '8': '৮',
                '9': '৯'
            };
    
            var output = [];
            for (var i = 0; i < engNumber.length; ++i) {
                if (numbers.hasOwnProperty(engNumber[i])) {
                    output.push(numbers[engNumber[i]]);
                } else {
                    output.push(engNumber[i]);
                }
            }
            return output.join('');
        }
    },

};