<template>
  <div class="container details-page">
    <Breadcrumbs :title="`${dataRow.title}`"></Breadcrumbs>

    <section class="section" :style="cmsStyle">
      <div v-if="dataRow.description || dataRow.image" class="section-contents text-justify">
        <img
          v-if="dataRow.image"
          :src="`${IMAGEURL}${dataRow.image}`"
          class="img-responsive cms-image"
        />
        <div v-html="dataRow.description"></div>
        <div class="clearfix"></div>
      </div>

      <div v-for="(item, index) in sections" :key="index" class="section-contents text-justify">
        <h5>{{ item.title }}</h5>
        <img
          v-if="item.image"
          :src="`${IMAGEURL}${item.image}`"
          class="img-responsive cms-section-image"
        />
        <div v-html="item.description"></div>
        <div class="clearfix"></div>
      </div>

    </section>
  </div>
</template>

<script>
import Breadcrumbs from "../../layouts/common/Breadcrumbs.vue";

export default {
  name: "HelloWorld",
  components: {
    Breadcrumbs,
  },
  data() {
    return {
      dataRow: {},
      sections: [],
    };
  },
  methods: {
    getData() {
      this.$http
        .get("cms/show/" + this.$route.params.slug)
        .then((response) => {
          this.dataRow = response.data;
          this.getSectionsData(response.data.id);
        })
        .catch((e) => {
          this.dataRow = {};
        });
    },
    getSectionsData(id) {
      this.$http
        .get("cms/Sections?ref=" + id)
        .then((response) => {
          this.sections = response.data.data;
          console.log('this.sections', this.sections)
        })
        .catch((e) => {
          this.sections = [];
        });
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    $route(to, from) {
      this.getData();
    },
  },
  computed: {
    cmsStyle() {
      return "background: green;" + "color: #fff";
    },
  },
};
</script>
