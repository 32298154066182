<template>
  <div class="container details-page">
    <div class="breadcrumbs">
      <div class="row">
        <div class="col-12">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'Home' }">Home</router-link>
              </li>
              <li class="breadcrumb-item">Login</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
    <section class="section">
      <div class="section-contents">
        <div class="row justify-content-center">
          <div class="col-lg-4">
            <form
              class="mb-5"
              v-if="loginType == 'mobile'"
              @submit="loginSubmit"
            >
              <div class="form-group">
                <label>Mobile Number</label>
                <input
                  ref="phone"
                  v-model="loginData.mobile"
                  class="form-control"
                  placeholder="Enter mobile number"
                />
              </div>
              <div class="form-group">
                <label>Password</label>
                <input
                  ref="password"
                  v-model="loginData.password"
                  type="password"
                  class="form-control"
                  placeholder="Enter password"
                />
              </div>
              <button type="submit" class="btn btn-primary">Login</button>
              <div class="mt-2">
                <router-link :to="{ name: 'ForgotPassword' }"
                  >Forgot your password click here</router-link
                ><br />
                <router-link :to="{ name: 'SignUp' }"
                  >Don't have an account create account
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "HelloWorld",
  data() {
    return {
      loginType: "mobile",
      loginData: {
        phone: null,
        password: null,
      },
      redirectUrl: '',
    };
  },
  computed: {
          ...mapGetters([
              'cart',
              'wishlist',
              'itemsCount',
              'subTotal'
          ])
  },
  mounted() {
    if (localStorage.getItem("token")) {
      this.flashMessage.success({
          message: "You are logged in already",
        });
      location.href = "customer/profile";
    }
  },
  methods: {
    loginSubmit: function(e) {
      e.preventDefault();
      if (!this.loginData.mobile) {
        this.$refs.mobile.focus();
        this.flashMessage.error({
          message: "Mobile number required",
        });
        return false;
      }
      if (!this.loginData.password) {
        this.$refs.password.focus();
        this.flashMessage.error({
          message: "Password required",
        });
        return false;
      }

      this.$http
        .post("login", this.loginData)
        .then((response) => {
          if (response.data.token) {
            this.$cookies.set("token", response.data.token, 0);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("customer_id", response.data.user_info.id);
            localStorage.setItem("name", response.data.user_info.name);
            this.flashMessage.success({
              message: "You logged in successfully.",
            });

            if (this.cart.length > 0) {
              this.cartInfoUpdate(response.data.token);
            } else {
              if (this.$route.query.rd) {
                location.href = this.$route.query.rd;
              } else {
                location.href = "customer/profile";
              }
            }
          } else {
            this.flashMessage.error({
              message: "Invalid mobile or password",
            });
          }
        })
        .catch((e) => {
          this.flashMessage.error({
            message: "Invalid mobile or password",
          });
          console.log(e);
        });
    },
    cartInfoUpdate(token) {
        var data = {
            session_id: localStorage.getItem('session_id')
        }
        this.$http.post('cart-info-update', data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            if (this.$route.query.rd) {
                location.href = this.$route.query.rd;
            } else {
                location.reload();
            }
        }).catch((e) => {
            console.log(e);
        })
    },
  },
};
</script>
